import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import About__header from "../images/about_main.jpg"
import About__middle from "../images/About.jpg"
import Magento_logo from "../images/magento_logo.png"
import Shopify_logo from "../images/shopify_logo.png"
import Team__pic from "../images/team.jpg"
import Culture_pic from "../images/culture.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component'

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'


const Aboutpage = () => (
  <Layout>
    <Helmet>
      <title>
        About CueBlocks - eCommerce Design and Development Company | Magento Trained Solution Agency | Digital
        Agency </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>

          <link rel="canonical" href="https://www.cueblocks.com/about.php"/>
          <meta name="description"
                content="Based out of Chandigarh, India, CueBlocks is a web design, development & marketing agency founded in July 2005 by Avneet, Sarthak & Pancham. Also a Magento 2 trained agency"/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/about.php"/>
          <meta property="og:title"
                content="About CueBlocks - eCommerce Design and Development Company | Magento Trained Solution Agency | Digital Agency"/>
          <meta property="og:description"
                content="Based out of Chandigarh, India, CueBlocks is a web design, development & marketing agency founded in July 2005 by Avneet, Sarthak & Pancham. Also a Magento 2 trained agency"/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="Based out of Chandigarh, India, CueBlocks is a web design, development & marketing agency founded in July 2005 by Avneet, Sarthak & Pancham. Also a Magento 2 trained agency"/>
          <meta name="twitter:title"
                content="About CueBlocks - eCommerce Design and Development Company | Magento Trained Solution Agency | Digital Agency"/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
    </Helmet>

    <section className="about_sec_row ">
      <div className="container">
        <div className="about_sec_row_lt pd_right_40">
          <h2 className="heading_main">The Advent of CueBlocks</h2>
          <div className="about_sec_row_lt_text">
            <p>
              They say that the journey of a thousand miles begins with a small step. We took that first step in 2005 with three new IBM machines and the ardent vision of 
              our founders - Pancham, Sarthak, and Avneet. Their mission - to provide the best design, digital marketing, and coding services that meet a universal standard. 
            </p><br/>
            <p>
              We rolled up our sleeves, polished our skills and took the big leap into the E-commerce industry. From designing to coding, we did it all! Getting acquainted with 
              a diverse clientele from around the world, we adapted skills and techniques that paved our path towards an international market. 
            </p>
          </div>
          <div className="about_sec_row_lt_img">
            <img
              src={About__header}
              alt="Cue Blocks"
              className="img-responsive"
            />
          </div>
          <div className="about_sec_row_lt_text mr_bt_0">
            <p>
              Fourteen years later, we are here & thriving! What’s changed? Now, we operate in global time zones working with a wide range of clients and businesses that help us to 
              stay the course and grow with each endeavor we undertake.
            </p>
          </div>
        </div>
        <div className="about_sec_row_rt pd_left_40">
          <h2 className="heading_main">Our journey</h2>

          <div className="about_sec_row_rt_img">
            <ScrollAnimation animateIn="fadeIn" animateOnce="true">
            <img
              src={About__middle}
              alt="Cue Blocks"
              className="img-responsive"
            />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>

    {/*  Specialization */}

    <section className="spcltn_sec_row ">
      <div className="container">
        <div className="spcltn_sec_row_div">
          <h2 className="heading_main">Super specialization</h2>
          <div className="spcltn_sec_row_div_lt pd_right_40">
            <p>
              An open-source E-commerce platform, Magento provides online merchants, a flexible shopping cart system that offers powerful marketing, search engine optimization,
               and catalog-management tools.  Since 2009, we have been involved in setting up Magento stores, building custom modules, migrating merchants to Magento, and
                contributing to the Magento community.
            </p>
            <div className="spcltn_sec_row_div_lt_img">
              <ScrollAnimation animateIn="pulse" animateOnce="true">
              <img
                src={Magento_logo}
                alt="Cue Blocks"
                className="img-responsive"
              />
              </ScrollAnimation>
            </div>
          </div>
          <div className="spcltn_sec_row_div_rt pd_left_40">
            <p>
              Shopify is a completely cloud-based E-commerce platform that lets you start, grow and manage a business. With Shopify, you can sell your products in multiple places
               including the web, mobile, social media, online marketplaces, brick & mortar locations, and pop-up shops. We have been partners with Shopify since 2016 and continue 
               to love the endless possibilities they offer to this day. 
            </p>
            <div className="spcltn_sec_row_div_rt_img">
              <ScrollAnimation animateIn="pulse" animateOnce="true" delay="1000" className="ddd">
                <LazyLoadImage
                src={Shopify_logo}
                alt="Cue Blocks"
                className="img-responsive"
              />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Business */}

    <section className="business_sec_row ">
      <div className="container">
        <div className="business_sec_row_div">
          <div className="business_sec_row_div_lt pd_right_40">
            
            <h2 className="heading_main">An Insight into our Foresight </h2>
          </div>
          <div className="business_sec_row_div_rt pd_left_40">
            <p>
              Having such a diverse bunch of professionals, differences in opinions are inevitable. However, we do have some things that, all of us as a team, agree on, 
              believe in, and abide by conscientiously. Here is what you can call the credo of CueBlocks.
            </p>
            <ul>
              <li>Celebrate every big and small milestone in life </li>
              <li>
                Develop a mental space that contemplates a broader perspective.{" "}
              </li>
              <li>Practice intellectual empathy.</li>
              <li>Foster active social ties.</li>
              <li>Integrity is a state of mind. </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    {/* Team */}

    <section className="team_sec_row ">
      <div className="container">
        <div className="team_sec_row_div">
          <div className="team_sec_row_div_lt pd_right_40">
            <h2 className="heading_main">Meet The Cue Team</h2>
            <p>
              Each individual brings their own character and charm to the workplace, making it an amazing place to work. We owe it to our fabulous CueTeam to always
               keep the wheels churning around here. Come! Get to know them a bit more.
            </p>
            <div className="team_sec_row_div_lt_btn">
              <Link to="/team" className="read_btn">
                Read More..
              </Link>
            </div>
            <div className="team_sec_row_div_lt_img">
              <ScrollAnimation animateIn="fadeIn" animateOnce="true">
              <img
                src={Team__pic}
                alt="Cue Blocks"
                className="img-responsive"
              />
              </ScrollAnimation>
            </div>
            <div className="team_sec_row_div_lt_career">
              <h2 className="heading_main">Be a CueBie</h2>
              <p>
                A CueBie is a brilliant, exuberant, and pragmatic individual who emanates good vibes.
                If you love the work that you do, are always brimming with ideas, and are sending out positive vibes, then we are looking for you. Come join the CueTeam

              </p>
              <Link to="/career" className="btn main_cta">
                Apply
              </Link>
            </div>
          </div>
          <div className="team_sec_row_div_rt pd_left_40">
            <h2 className="heading_main">Life @ CueBlocks</h2>
            <p>
              Customers will never love a company until the employees love it first. And we truly love what we do! From giving quality work to unwinding with some
               much-needed game-time, we do it all here. We take pride in our work and create opportunities to do more amazing things every day. A workspace that 
               is full of brilliant, technically sound, and humble people, we are welcoming of everything and everyone. And if you exude positive vibes, you get 
               special brownie points. 
            </p>
            <div className="team_sec_row_div_rt_btn">
              <Link to="/career" className="read_btn">
                Read More..
              </Link>
            </div>
            <div className="team_sec_row_div_rt_img">
              <img
                src={Culture_pic}
                alt="Cue Blocks"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default Aboutpage